import Location1 from "./Location1.jpg";
import Location2 from "./Location2.jpg";
import Location3 from "./Location3.jpg";
import Location4 from "./Location4.jpg";

const imageSlide2 = [
  {
    url: Location1,
  },
  {
    url: Location2,
  },
  {
    url: Location3,
  },
  {
    url: Location4,
  },
  {
    url: "Location5.jpg",
  },
  {
    url: "Location6.jpg",
  },
];
export default imageSlide2;
