const imageSlide = [
  {
    url: "pic-1.jpg",
    title: "FEATURED PROJECT:",
    body: "YELLOW FALLS",
  },
  {
    url: "pic-2.jpg",
    title: "FEATURED PROJECT:",
    body: "WATER AND PLANTS",
  },
  {
    url: "pic-3.jpg",
    title: "FEATURED PROJECT:",
    body: "ANGELS IN THE SKY",
  },
  {
    url: "pic-4.jpg",
    title: "FEATURED PROJECT:",
    body: "THE MILL",
  },
  {
    url: "pic-5.jpg",
    title: "FEATURED PROJECT:",
    body: "THE MILL",
  },
  {
    url: "pic-6.jpg",
    title: "FEATURED PROJECT:",
    body: "",
  },
];
export default imageSlide;
